<template>
<div class="newsPage">
<news-header></news-header>
  <news-nav @navChange="navChange"></news-nav>
  <div class="newsMain">
    <div class="newsTitle">
      {{nowNews.newsTitle}}
    </div>
    <div class="newsImg">
      <img :src="nowNews.imgPath">

    </div>

    <div class="newsContent" v-html="nowNews.newsContent">

    </div>
    <div class="pageChangeLine">
      <div class="prevBtn" v-if="nIndex>0" @click="newsPrevFc">
        上一篇:{{nowData[nIndex-1].newsTitle}}
      </div>
      <div class="nextBtn" v-if="nIndex<3" @click="newsNextFc">
        下一篇:{{nowData[nIndex+1].newsTitle}}
      </div>
    </div>
  </div>
  <page-bom></page-bom>
</div>
</template>

<script>
import NewsHeader from "../../components/newsPage/newsHeader";
import NewsNav from "../../components/newsPage/newsNav";
import PageBom from "../../components/newsPage/pageBom";
export default {
  name: "newsPage",
  components: {PageBom, NewsNav, NewsHeader},
  data(){
    return{
      nType:null,
      nIndex:null,
      nData:null,
      nowData:null,
      nowNews:null
    }
  },
  methods:{
    newsPrevFc(){
      this.nIndex--;
      this.nowNews=this.nowData[this.nIndex]
    },
    newsNextFc(){
      this.nIndex++;
      this.nowNews=this.nowData[this.nIndex]
    },
    navChange(option) {
      this.nType=option;
      this.nIndex=0;
      switch (this.nType){
        case "dynamic":
          this.nowData=this.nData.dynamic;
          break;
        case "realInfo":
          this.nowData=this.nData.realInfo;
          break;
        case "mediaCan":
          this.nowData=this.nData.mediaCan;

      }
      this.nowNews=this.nowData[this.nIndex]
    }
  },
  created() {
   this.nType=this.$store.state.newsType;
    this.nData=this.$store.state.newsData;
    this.nIndex=this.$store.state.newsIndex;
    switch (this.nType){
      case "dynamic":
        this.nowData=this.nData.dynamic;
        break;
        case "realInfo":
          this.nowData=this.nData.realInfo;
          break;
          case "mediaCan":
            this.nowData=this.nData.mediaCan;

    }
    this.nowNews=this.nowData[this.nIndex]
  },
  mounted() {

  }
}
</script>

<style lang="scss" scoped>
.newsPage{
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar{
    width:10px;
  }
  //滚动条小方块
  &::-webkit-scrollbar-thumb{
    border-radius:5px;
    background:rgb(167, 198, 156);
  }
  //滚动条轨道
  &::-webkit-scrollbar-track{
    border-radius:0px;
    height:100px;
    background:rgba(167, 198, 156,0.3);
  }
}
.newsMain{
  position: relative;
  width: 1200px;
  margin: 0 auto;
}
.newsTitle{
  margin: 30px 0 50px 0;
}
.newsImg{
  max-height: 700px;
  overflow: hidden;
  img{
    width: 1200px;
    height: auto;
  }
}
.newsContent{
  margin-top: 60px;
  font-size: 16px;
  color: #666666;
  line-height: 30px;
}
.pageChangeLine{
  font-size: 22px;
  color: #666666;
  display: flex;
  justify-content: space-between;
  margin: 100px 0;
}
.prevBtn{
  position: absolute;
  left: 0;
  bottom: 0;
  cursor: pointer;
  width: 300px;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap
}
.nextBtn{
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
  width: 300px;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap
}
</style>
