<template>
<div class="pageBom">
  <div class="footerBom">
    ©2021 浙江国通电气科技有限公司 版权所有 浙ICP备20006768号-1

  </div>
</div>
</template>

<script>
export default {
  name: "pageBom"
}
</script>

<style  scoped>
.footerBom{
  position: relative;
  width: 1920px;
  height: 50px;
  background: black;
  font-size: 12px;
  text-align: center;
  line-height: 50px;
  color: white;
}
</style>
