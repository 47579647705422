<template>
<div class="newsHeader">
<img src="../../assets/imgs/newsPage/header.png">
  <div class="returnRight" @click="returnFc">
    <div class="returnTxt">返回</div>
    <div class="returnImg">
      <img src="../../assets/imgs/svgs/returnRight.svg">
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "newsHeader",
  data(){
    return{

    }
  },
  methods:{
    returnFc(){
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.newsHeader{
  position: relative;
  width: 1920px;
  height: 75px;
  background: #A7C69C;
  padding: 17px 0 0 380px;
  box-sizing: border-box;
  img{

    height: 40px;
    width: auto;
  }
}
.returnRight{
  position: absolute;
  top:17px;
  right: 380px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;

  .returnTxt{
   color: white;
    font-size: 23px;
    line-height: 40px;
    margin-right: 10px;
  }
  .returnImg{
    position: relative;
    width: 40px;

    img{
      width: 40px;
      height: auto;
    }
  }
}

</style>
