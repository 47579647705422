<template>
<div class="newsNav">
<div class="frameMain">
  <div class="frameImg">
    <img src="../../assets/imgs/newsPage/newsIcon.png">
  </div>
  <div class="navLine">
    <div class="navEver" @click="navChange('dynamic')" :class="{'navChecked':(navPk=='dynamic')}">公司动态</div>
    <div class="navEver" @click="navChange('realInfo')" :class="{'navChecked':(navPk=='realInfo')}">行业资讯</div>
    <div class="navEver" @click="navChange('mediaCan')" :class="{'navChecked':(navPk=='mediaCan')}">媒体频道</div>

  </div>
  <div class="fakerLine"></div>
</div>
</div>
</template>

<script>
export default {
  name: "newsNav",
  data(){
    return{
      navPk:'dynamic'
    }
  },
  methods:{
    navChange(option){
      this.navPk=option;
      this.$emit("navChange",option);
    }
  }
}
</script>

<style  lang="scss" scoped>
.newsNav{
  position: relative;
margin: 0 auto;
  width: 1920px;
  height: 150px;
  border-bottom: 1px solid #707070;

}
.frameMain{
  margin: 0 auto;
  width: 1200px;
  height: 150px;
}
.frameImg{
  margin-top: 10px;
  img{
    width: 270px;
    height: auto;
  }
}
.navLine{
  width: 1200px;
  height: 40px;
  margin-top: 20px;
  display: flex;

}
.navEver{
  font-size: 18px;
  margin-right: 30px;
  color: #666666;
  cursor: pointer;
}
.navChecked{
  border-bottom: 5px solid #EEAA3D;

}

</style>
